





































































































































































































































































































































































































































































































































































































































































































































































































































































































.content {
  margin-top: 28px;
  h2{
    margin-bottom: -10px;
  }
}
.subtitle-box {
  align-items: center;
  margin-top: 12px;
}
.create-button {
  text-align: right;
}
.dropbox {
  margin-top: 20px;
}
.checkbox {
  width: 20px;
  height: 20px;
}
.refresh-icon {
  cursor: pointer;
  margin-left: 5px;
}
.mt-16 {
  margin-top: 16px;
}
.content-region {
  width: 100%;
  /*border: 1px solid #BDBDBD;*/
}
.title {
  font-weight: bold;
  font-size: 20px;
}
.subtitle {
  font-weight: bold;
  font-size: 16px;
}
.loading-text {
  margin-top: 46px;
  font-size: 18px;
}
.start-over-button {
  margin-right: 20px;
}
.handle{
  cursor: pointer;
}
